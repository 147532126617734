export const mixin = {
  methods: {
    undeveloped(){
      this.$message('功能暂未开放，敬请期待！');
    },
    todataTImes(prevs){           
      var nowTime = +new Date(); //没有参数，返回的是当前时间总的毫秒数
      var inputTime = +new Date(prevs); // 有参数，返回的是用户输入时间的总毫秒数
      var times = (inputTime - nowTime) / 1000; //times就是剩余时间的总的秒数
      times=times-times-times
      var ToTimes='未知'
      var d = parseInt(times / 60 / 60 / 24); //天数
      if(d>=1&&d<2){
          ToTimes='一天前'
      }else if(d>=2&&d<3){
          ToTimes='二天前'
      }else if(d>=3){
          ToTimes='三天前'
      }else if(d<1){
          ToTimes='今天'
      }
      return ToTimes     
  }
    }
}

<template>
<div>
    <div class="wrapper">
        <main class="main-content">
            <!--== Start Hero Area Wrapper ==-->
            <section class="home-slider-area">
            <div class="home-slider-container default-slider-container">
                <div class="home-slider-wrapper slider-default">
                <div class="slider-content-area backgr" data-bg-img="assets/img/photos/bg1.webp">

                    <!-- 此处添加banner内容 -->
                    <div class="container pt--0 pb--0">
                    <div class="slider-container">
                        <div class="row justify-content-center align-items-center">
                        <div class="col-12 col-lg-8">
                            <div class="slider-content">
                            <h2 class="title">全新升级</h2>
                            <h2 class="title">重磅回归，为你而来！</h2>

                            </div>
                        </div>
                        <div class="col-12">
                            <div class="job-search-wrap">
                            <div class="job-search-form">
                                <form action="#">
                                <div class="row row-gutter-10">
                                    <div class="col-lg-auto col-sm-6 col-12 flex-grow-1">
                                    <div class="form-group">
                                        <input type="text" class="form-control" placeholder="输入内容全站搜索" v-model="name">
                                    </div>
                                    </div>
                                    <div class="col-lg-auto col-sm-6 col-12 flex-grow-1">
                                    <div class="form-group">
                                        <button type="button" class="btn-form-search" @click="toByNameOrLabers"><i class="icofont-search-1"></i></button>
                                    </div>
                                    </div>
                                </div>
                                </form>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                    <!-- 此处添加banner内容 -->


                </div>
                </div>
            </div>
            <div class="container pt--0 pb--0">
                <div class="row">
                <div class="col-12">
                    <div class="play-video-btn">
                    <a href="/ModsView" class="video-popup" style="color:#fff">
                    Go
                        <!-- <img src="static/picture/play.webp" alt="Image-HasTech"> -->
                    </a>
                    </div>
                </div>
                </div>
            </div>
            <div class="home-slider-shape">
                <img class="shape1" vue-aos="fade-down" vue-aos-duration="1500" src="../../../public/static/picture/vector1.webp" width="270" height="234" alt="Image-HasTech">
                <img class="shape2" vue-aos="fade-left" vue-aos-duration="2000" src="../../../public/static/picture/vector2.webp" width="201" height="346" alt="Image-HasTech">
                <img class="shape3" vue-aos="fade-right" vue-aos-duration="2000" src="../../../public/static/picture/vector3.webp" width="276" height="432" alt="Image-HasTech">
                <img class="shape4" vue-aos="flip-left" vue-aos-duration="1500" src="../../../public/static/picture/vector4.webp" width="127" height="121" alt="Image-HasTech">
            </div>
            </section>
            <!--== End 免费模板 ==-->

            <!--== Start 免费模板 ==-->
            <section class="recent-job-area bg-color-gray">
            <div class="container" vue-aos="fade-down" style="margin-top: -50px;">
                <div class="row">
                <div class="col-12">
                    <div class="section-title text-center">
                    <h3 class="title">海量模板免费下载</h3>
                    <div class="desc">
                        <p>海量Word、Excel、PPT等各系列模板，陆续上传中</p>
                    </div>
                    </div>
                </div>
                </div>
                <div class="row">
                <div class="col-md-6 col-lg-4">
                    <!--== Start Recent Job Item ==-->
                    <div class="recent-job-item">
                    <div class="company-info">
                        <div class="logo">
                        <a href="javascript:;"><img src="assets\img\photos\wordlogo.png" width="75" height="75" alt="Image-HasTech"></a>
                        </div>
                        <div class="content">
                        <h4 class="name"><a href="javascript:;">Word</a></h4>
                        <p class="address">简历、总结、合同、公文</p>
                        </div>
                    </div>
                    <div class="main-content">
                        <h3 class="title"><a href="job-details.html">海量模板免费下载</a></h3>
                        <h5 class="work-type">仅供学习使用</h5>
                        <p class="desc">全部免费使用，真诚无套路！</p>
                    </div>
                    <div class="recent-job-info">
                        <div class="salary">
                        <h4>免费</h4>

                        </div>
                        <router-link :to="{path:'/ModsOf',query: {key:'word'}}" class="btn-theme btn-sm">前往专区</router-link>
                    </div>
                    </div>
                    <!--== End Recent Job Item ==-->
                </div>
                <div class="col-md-6 col-lg-4">
                    <!--== Start Recent Job Item ==-->
                    <div class="recent-job-item">
                    <div class="company-info">
                        <div class="logo">
                        <a href="javascript:;"><img src="assets\img\photos\EXCEL.png" width="75" height="75" alt="Image-HasTech"></a>
                        </div>
                        <div class="content">
                        <h4 class="name"><a href="javascript:;">Excel</a></h4>
                        <p class="address">简历、总结、合同、公文</p>
                        </div>
                    </div>
                    <div class="main-content">
                        <h3 class="title"><a href="job-details.html">海量模板免费下载</a></h3>
                        <h5 class="work-type">仅供学习使用</h5>
                        <p class="desc">全部免费使用，真诚无套路！</p>
                    </div>
                    <div class="recent-job-info">
                        <div class="salary">
                        <h4>免费</h4>

                        </div>
                        <router-link :to="{path:'/ModsOf',query: {key:'excel'}}" class="btn-theme btn-sm">前往专区</router-link>
                    </div>
                    </div>
                    <!--== End Recent Job Item ==-->
                </div>
                <div class="col-md-6 col-lg-4">
                    <!--== Start Recent Job Item ==-->
                    <div class="recent-job-item">
                    <div class="company-info">
                        <div class="logo">
                        <a href="javascript:;"><img src="assets\img\photos\PPTlogo.png" width="75" height="75" alt="Image-HasTech"></a>
                        </div>
                        <div class="content">
                        <h4 class="name"><a href="javascript:;">PPT</a></h4>
                        <p class="address">简历、总结、合同、公文</p>
                        </div>
                    </div>
                    <div class="main-content">
                        <h3 class="title"><a href="job-details.html">海量模板免费下载</a></h3>
                        <h5 class="work-type">仅供学习使用</h5>
                        <p class="desc">全部免费使用，真诚无套路！</p>
                    </div>
                    <div class="recent-job-info">
                        <div class="salary">
                        <h4>免费</h4>

                        </div>
                        <router-link :to="{path:'/ModsOf',query: {key:'ppt'}}" class="btn-theme btn-sm">前往专区</router-link>
                    </div>
                    </div>
                    <!--== End Recent Job Item ==-->
                </div>
                <div class="col-md-6 col-lg-4">
                    <!--== Start Recent Job Item ==-->
                    <div class="recent-job-item">
                    <div class="company-info">
                        <div class="logo">
                        <a href="javascript:;"><img src="assets\img\photos\adlogo.png" width="75" height="75" alt="Image-HasTech"></a>
                        </div>
                        <div class="content">
                        <h4 class="name"><a href="javascript:;">广告设计</a></h4>
                        <p class="address">简历、总结、合同、公文</p>
                        </div>
                    </div>
                    <div class="main-content">
                        <h3 class="title"><a href="job-details.html">海量模板免费下载</a></h3>
                        <h5 class="work-type">仅供学习使用</h5>
                        <p class="desc">全部免费使用，真诚无套路！</p>
                    </div>
                    <div class="recent-job-info">
                        <div class="salary">
                        <h4>免费</h4>

                        </div>
                        <router-link :to="{path:'/ModsOf',query: {key:'pic'}}" class="btn-theme btn-sm">前往专区</router-link>
                        
                    </div>
                    </div>
                    <!--== End Recent Job Item ==-->
                </div>
                <div class="col-md-6 col-lg-4">
                    <!--== Start Recent Job Item ==-->
                    <div class="recent-job-item">
                    <div class="company-info">
                        <div class="logo">
                        <a href="javascript:;"><img src="assets\img\photos\zylogo.png" width="75" height="75" alt="Image-HasTech"></a>
                        </div>
                        <div class="content">
                        <h4 class="name"><a href="javascript:;">资源分享区</a></h4>
                        <p class="address">简历、总结、合同、公文</p>
                        </div>
                    </div>
                    <div class="main-content">
                        <h3 class="title"><a href="job-details.html">海量模板免费下载</a></h3>
                        <h5 class="work-type">仅供学习使用</h5>
                        <p class="desc">全部免费使用，真诚无套路！</p>
                    </div>
                    <div class="recent-job-info">
                        <div class="salary">
                        <h4>免费</h4>

                        </div>
                        <a class="btn-theme btn-sm" href="job-details.html">前往专区</a>
                    </div>
                    </div>
                    <!--== End Recent Job Item ==-->
                </div>


                </div>
            </div>
            </section>
            <!--== Start 标签检索 ==-->
            <LabelRetrieval></LabelRetrieval>
            <!--== End 标签检索 ==-->

            <!--== Start 付费业务 ==-->
            <section class="testimonial-area bg-color-gray">
            <div class="container" vue-aos="fade-down">
                <div class="row">
                <div class="col-12">
                    <div class="section-title text-center">
                    <h3 class="title">付费业务</h3>
                    <div class="desc">
                        <p>有了喜欢的模板但不会使用怎么办，联系我们帮您解决！</p>
                    </div>
                    </div>
                </div>
                </div>
                <div class="row">
                <div class="col-12">
                    <div class="swiper testi-slider-container">
                        <div class="swiper-wrapper" >
                            <div class="swiper-slide">
                            <!--== Start Testimonial Item ==-->
                            <div class="testimonial-item">
                                <a href="javascript:;" >
                                <div class="testi-inner-content">
                                <div class="testi-author">
                                    <div class="testi-thumb">
                                    <img src="assets\img\photos\codelogo.png" width="75" height="75" alt="Image-HasTech">
                                    </div>
                                    <div class="testi-info">
                                    <h4 class="name">网站开发</h4>
                                    <span class="designation">王牌业务</span>
                                    </div>
                                </div>
                                <div class="testi-content">
                                    <p class="desc">企业官网开发、各类管理系统开发、各类操作平台开发</p>
                                    <div class="rating-box">
                                    <i class="icofont-star"></i>
                                    <i class="icofont-star"></i>
                                    <i class="icofont-star"></i>
                                    <i class="icofont-star"></i>
                                    <i class="icofont-star"></i>
                                    </div>
                                    <div class="testi-quote"><img src="static/picture/quote1.webp" alt="Image-HasTech"></div>
                                </div>
                                </div>
                                </a>
                            </div>
                            <!--== End Testimonial Item ==-->
                            </div>
                            <div class="swiper-slide">
                            <!--== Start Testimonial Item ==-->
                            <div class="testimonial-item">
                                <a href="javascript:;" >
                                <div class="testi-inner-content">
                                <div class="testi-author">
                                    <div class="testi-thumb">
                                    <img src="assets\img\photos\wordlogo.png" width="75" height="75" alt="Image-HasTech">
                                    </div>
                                    <div class="testi-info">
                                    <h4 class="name">Word文档制作</h4>
                                    <span class="designation">金牌业务</span>
                                    </div>
                                </div>
                                <div class="testi-content">
                                    <p class="desc">有了喜欢的模板但不会使用怎么办，联系我们帮您解决！</p>
                                    <div class="rating-box">
                                    <i class="icofont-star"></i>
                                    <i class="icofont-star"></i>
                                    <i class="icofont-star"></i>
                                    <i class="icofont-star"></i>
                                    <i class="icofont-star"></i>
                                    </div>
                                    <div class="testi-quote"><img src="static/picture/quote1.webp" alt="Image-HasTech"></div>
                                </div>
                                </div>
                                </a>
                            </div>
                            <!--== End Testimonial Item ==-->
                            </div>
                            
                            <div class="swiper-slide">
                            <!--== Start Testimonial Item ==-->
                            <div class="testimonial-item">
                                <a href="javascript:;" >
                                <div class="testi-inner-content">
                                <div class="testi-author">
                                    <div class="testi-thumb">
                                    <img src="assets\img\photos\PPTlogo.png" width="75" height="75" alt="Image-HasTech">
                                    </div>
                                    <div class="testi-info">
                                    <h4 class="name">PPT制作</h4>
                                    <span class="designation">金牌业务</span>
                                    </div>
                                </div>
                                <div class="testi-content">
                                    <p class="desc">有了喜欢的模板但不会使用怎么办，联系我们帮您解决！</p>
                                    <div class="rating-box">
                                    <i class="icofont-star"></i>
                                    <i class="icofont-star"></i>
                                    <i class="icofont-star"></i>
                                    <i class="icofont-star"></i>
                                    <i class="icofont-star"></i>
                                    </div>
                                    <div class="testi-quote"><img src="static/picture/quote1.webp" alt="Image-HasTech"></div>
                                </div>
                                </div>
                                </a>
                            </div>
                            <!--== End Testimonial Item ==-->
                            </div>
                            <div class="swiper-slide">
                            <!--== Start Testimonial Item ==-->
                            <div class="testimonial-item">
                                <a href="javascript:;" >
                                <div class="testi-inner-content">
                                    <div class="testi-author">
                                    <div class="testi-thumb">
                                        <img src="assets\img\photos\EXCEL.png" width="75" height="75" alt="Image-HasTech">
                                    </div>
                                    <div class="testi-info">
                                        <h4 class="name">Excel表格制作</h4>
                                        <span class="designation">金牌业务</span>
                                    </div>
                                    </div>
                                    <div class="testi-content">
                                    <p class="desc">有了喜欢的模板但不会使用怎么办，联系我们帮您解决！</p>
                                    <div class="rating-box">
                                        <i class="icofont-star"></i>
                                        <i class="icofont-star"></i>
                                        <i class="icofont-star"></i>
                                        <i class="icofont-star"></i>
                                        <i class="icofont-star"></i>
                                    </div>
                                    <div class="testi-quote"><img src="static/picture/quote1.webp" alt="Image-HasTech"></div>
                                    </div>
                                </div>
                                </a>
                            </div>
                            <!--== End Testimonial Item ==-->
                            </div>
                            <div class="swiper-slide">
                            <!--== Start Testimonial Item ==-->
                            <div class="testimonial-item">
                                <a href="javascript:;" >
                                <div class="testi-inner-content">
                                    <div class="testi-author">
                                    <div class="testi-thumb">
                                        <img src="assets\img\photos\adlogo.png" width="75" height="75" alt="Image-HasTech">
                                    </div>
                                    <div class="testi-info">
                                        <h4 class="name">广告制作</h4>
                                        <span class="designation">金牌业务</span>
                                    </div>
                                    </div>
                                    <div class="testi-content">
                                    <p class="desc">有了喜欢的模板但不会使用怎么办，联系我们帮您解决！</p>
                                    <div class="rating-box">
                                        <i class="icofont-star"></i>
                                        <i class="icofont-star"></i>
                                        <i class="icofont-star"></i>
                                        <i class="icofont-star"></i>
                                        <i class="icofont-star"></i>
                                    </div>
                                    <div class="testi-quote"><img src="static/picture/quote1.webp" alt="Image-HasTech"></div>
                                    </div>
                                </div>
                                </a>
                            </div>
                        <!--== End Testimonial Item ==-->
                        </div>
                    </div>

                    <!--== Add Swiper Pagination ==-->
                    <div class="swiper-pagination"></div>
                    </div>
                  </div>
                </div>
            </div>
            </section>
            <!--== End Testimonial Area Wrapper ==-->


            <!--== Start 如何制作 ==-->
            <section class="work-process-area">
            <div class="container" vue-aos="fade-down">
                <div class="row">
                <div class="col-12">
                    <div class="section-title text-center">
                    <h3 class="title">制作过程</h3>
                    <div class="desc">
                        <p>制作流程图解</p>
                    </div>
                    </div>
                </div>
                </div>
                <div class="row">
                <div class="col-12">
                    <div class="working-process-content-wrap">
                    <div class="working-col">
                        <!--== Start Work Process ==-->
                        <div class="working-process-item">
                        <div class="icon-box">
                            <div class="inner">
                            <img class="icon-img" src="static/picture/w11.webp" alt="Image-HasTech">
                            <img class="icon-hover" src="static/picture/w1-hover.webp" alt="Image-HasTech">
                            </div>
                        </div>
                        <div class="content">
                            <h4 class="title">联系客服</h4>
                            <p class="desc">联系客服定制您要制作的内容</p>
                        </div>
                        <div class="shape-arrow-icon">
                            <img class="shape-icon" src="static/picture/right-arrow.webp" alt="Image-HasTech">
                            <img class="shape-icon-hover" src="static/picture/right-arrow2.webp" alt="Image-HasTech">
                        </div>
                        </div>
                        <!--== End Work Process ==-->
                    </div>
                    <div class="working-col">
                        <!--== Start Work Process ==-->
                        <div class="working-process-item">
                        <div class="icon-box">
                            <div class="inner">
                            <img class="icon-img" src="static/picture/w31.webp" alt="Image-HasTech">
                            <img class="icon-hover" src="static/picture/w3-hover.webp" alt="Image-HasTech">
                            </div>
                        </div>
                        <div class="content">
                            <h4 class="title">查看内容</h4>
                            <p class="desc">客服查看内容并联系制作者</p>
                        </div>
                        <div class="shape-arrow-icon">
                            <img class="shape-icon" src="static/picture/right-arrow.webp" alt="Image-HasTech">
                            <img class="shape-icon-hover" src="static/picture/right-arrow2.webp" alt="Image-HasTech">
                        </div>
                        </div>
                        <!--== End Work Process ==-->
                    </div>
                    <div class="working-col">
                        <!--== Start Work Process ==-->
                        <div class="working-process-item">
                        <div class="icon-box">
                            <div class="inner">
                            <img class="icon-img" src="static/picture/w21.webp" alt="Image-HasTech">
                            <img class="icon-hover" src="static/picture/w2-hover.webp" alt="Image-HasTech">
                            </div>
                        </div>
                        <div class="content">
                            <h4 class="title">开始制作</h4>
                            <p class="desc">制作者开始制作，按照约定好的期限耐心等待即可</p>
                        </div>
                        <div class="shape-arrow-icon">
                            <img class="shape-icon" src="static/picture/right-arrow.webp" alt="Image-HasTech">
                            <img class="shape-icon-hover" src="static/picture/right-arrow2.webp" alt="Image-HasTech">
                        </div>
                        </div>
                        <!--== End Work Process ==-->
                    </div>
                    <div class="working-col">
                        <!--== Start Work Process ==-->
                        <div class="working-process-item">
                        <div class="icon-box">
                            <div class="inner">
                            <img class="icon-img" src="static/picture/w41.webp" alt="Image-HasTech">
                            <img class="icon-hover" src="static/picture/w4-hover.webp" alt="Image-HasTech">
                            </div>
                        </div>
                        <div class="content">
                            <h4 class="title">定制完成</h4>
                            <p class="desc">按照期限将定制的成品发送给您(如不满意可联系客服进行修改)</p>
                        </div>
                        <div class="shape-arrow-icon d-none">
                            <img class="shape-icon" src="static/picture/right-arrow.webp" alt="Image-HasTech">
                            <img class="shape-icon-hover" src="static/picture/right-arrow2.webp" alt="Image-HasTech">
                        </div>
                        </div>
                        <!--== End Work Process ==-->
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </section>
            <!--== End Work Process Area Wrapper ==-->

            <!--== Start Divider Area Wrapper ==-->
            <section class="sec-overlay sec-overlay-theme" >
            <div class="container pt--0 pb--0">
                <div class="row justify-content-center divider-style1">
                <div class="col-lg-10 col-xl-7">
                    <div class="divider-content text-center">
                    <h4 class="sub-title" vue-aos="fade-down">APP下载</h4>
                    <h2 class="title" vue-aos="fade-down">下载我们的移动端APP <br>您可以通过一下渠道进行下载。</h2>
                    <div class="divider-btn-group">
                        <a class="btn-divider" vue-aos="fade-down" @click="undeveloped()">
                        <h4 class="sub-title" vue-aos="fade-down">开发中，即将开放......</h4>
                        </a>
                        
                        <!-- <a class="btn-divider" vue-aos="fade-down" @click="undeveloped()">
                        <img src="static/picture/google-play.webp" width="201" height="63" class="icon" alt="Image-HasTech">
                        </a>
                        <a class="btn-divider btn-divider-app-store" vue-aos="fade-down" @click="undeveloped()">
                        <img src="static/picture/mac-os.webp" width="201" height="63" class="icon" alt="Image-HasTech">
                        </a> -->
                    </div>
                    </div>
                </div>
                </div>
            </div>
            <div class="bg-layer-style1"></div>
            <div class="bg-layer-style2"></div>
            </section>
            <!--== End Divider Area Wrapper ==-->
            






            <!--== Start 新闻中心 ==-->
            <section class="blog-area blog-home-area">
            <div class="container">
                <div class="row">
                <div class="col-12">
                    <div class="section-title text-center">
                    <h3 class="title">新闻中心</h3>
                    <div class="desc">
                        <p>每一条关于我们的信息都想和你分享</p>
                        <p>Every piece of information about us would like to share with you</p>
                    </div>
                    </div>
                </div>
                </div>
                <div class="row align-items-center post-home-style row-gutter-40">
                <div class="col-md-6 col-lg-4" vue-aos="fade-right" v-for="(item,index) in tableData" :key="index">
                    <!--== Start Blog Post Item ==-->
                    <div class="post-item">
                        <div class="thumb" @click="toNewsList(item.id)">
                            <img :src="item.pic" alt="Image" style="cursor: pointer;" width="370" height="270">
                        </div>
                        <div class="content">
                            <div class="author">By <router-link :to="{path:'/newsOf'}"><a href="">新闻中心</a></router-link></div>
                            <h4 class="title" style="cursor: pointer;" @click="toNewsList(item.id)">{{item.name}}</h4>
                            <div class="meta">
                            <span class="post-date">{{item.upload.slice(0,10)}}</span>
                            <span class="dots"></span>
                            <span class="post-time">{{todataTImes(item.upload)}}</span>
                            </div>
                        </div>
                    </div>

                    <!--== End Blog Post Item ==-->
                </div>

                
                </div>
            </div>
            <div class="row">
                <a href="#">
                <div class="col-12">
                <div class="section-title text-center">

                    <div class="desc">
                    <p>—more—</p>

                    </div>
                </div>
                </div>
                </a>
            </div>
            </section>
            <!--== End 新闻中心 ==-->
        </main>
    </div>
    </div>
</template>

<script>
import { mixin } from '../../mixins'
import {getAllNews } from '../../api/index'
import LabelRetrieval from '../../components/LabelRetrieval.vue'
export default {
    mixins: [mixin],
    data(){
        return{
            name:'',
            tableData:[]
        }
    },
    components:{
        LabelRetrieval
   },
   created(){
    this.getNews()
   },
   methods:{
        toByNameOrLabers(){
            this.$router.push({path: `/ModsBy`, query: {name:this.name}})
        },
        toNewsList(val){
            this.$router.push({path: `/newList`, query: {id: val}})
        },
        // 获取数据
        getNews () {
            this.tableData = []
            getAllNews().then(res => {
                this.tableData = res.slice(0,3)
            console.log("新闻：",res);
            })
        },
        todataTImes(prevs){           
            var nowTime = +new Date(); //没有参数，返回的是当前时间总的毫秒数
            var inputTime = +new Date(prevs); // 有参数，返回的是用户输入时间的总毫秒数
            var times = (inputTime - nowTime) / 1000; //times就是剩余时间的总的秒数
            times=times-times-times
            var ToTimes='未知'
            var d = parseInt(times / 60 / 60 / 24); //天数
            if(d>=1&&d<2){
                ToTimes='一天前'
            }else if(d>=2&&d<3){
                ToTimes='二天前'
            }else if(d>=3){
                ToTimes='三天前'
            }else if(d<1){
                ToTimes='今天'
            }
            return ToTimes     
        }
        
    }
   
}
</script>

<style scoped>
    .backgr{
        background-image: url('../../../public/assets/img/slider/banner1031.jpg');
    }
</style>
/* eslint-disable*/
import { get, post, deletes } from './http'



// =======================> 用户 API
// 注册用户
export const getRegisUser = (params) => post(`users/register`,params)
//用户登录
export const getLoginStatus = (params) => post(`users/login/status`, params)
//用户找回密码
export const getFindPass = (params) => post(`users/findPass`, params)
//用户修改密码
export const getUpPass = (params) => post(`users/upPass`, params)

// 返回所有用户
export const getAllUser = () => get(`user`)
// 返回指定账号的用户
export const getUserOfAccnumber = (accnumber) => get(`users/selusers?accnumber=${accnumber}`)
// 添加用户
export const setUser = (params) => post(`user/add`, params)
// 更新用户信息
export const updateUserMsg = (params) => post(`users/update`, params)
// 删除用户
export const deleteUser = (id) => get(`user/delete?id=${id}`)


// =======================> 下载记录 API
// 添加下载记录
export const setRecord = (params) => post(`record/addRecord`,params)
// 用户查询记录
export const RecordByDownuser = (downuser) => get(`record/selectByDownuser?downuser=${downuser}`)

// =======================> 新闻 API
// 所有新闻记录
export const getAllNews = () => get(`news/allNews`)
// 新闻内容查询
export const NewsByWid = (id) => get(`news/selectByPrimaryKey?id=${id}`)
// 添加标签
export const setNews = (params) => post(`news/add`, params)
// 更新标签信息
export const updateNews = (params) => post(`news/update`, params)
//删除新闻
export const deleteNews = (id) => get(`news/delete?id=${id}`)

// =======================> 标签 API
// 返回所有标签
export const getAllLables = () => get(`lables/allLables`)
// 添加标签
export const setLables = (params) => post(`lables/add`, params)
// 更新标签信息
export const updateLables = (params) => post(`lables/update`, params)
// 删除标签
export const deleteLabels = (labelsid) => get(`lables/delete?labelsid=${labelsid}`)

// =======================> 模板 API
// 返回所有模板
export const getAllModes = () => get(`words/allWords`)
// 返回Word模板
export const getAllWords = () => get(`words/selectWords`)
// 返回Excel模板
export const getAllExcel = () => get(`words/selectExcel`)
// 返回Ppt模板
export const getAllPpt = () => get(`words/selectPpt`)
// 返回Png模板
export const getAllPng = () => get(`words/selectPng`)
// 返回Resu模板
export const getAllResu = () => get(`words/selectResu`)
//根据id查询
export const selectWordByPrimaryKey = (id) => get(`words/selectByPrimaryKey?id=${id}`)
//根据标题或关键词查询
export const selectByNameOrLaber = (name) => get(`words/selectByNameOrLaber?name=${name}`)
// 添加模板
export const setWords = (params) => post(`words/add`, params)
// 更新模板信息
export const updateWords = (params) => post(`words/update`, params)
// 删除标签
export const deleteWords = (id) => get(`words/delete?id=${id}`)

// =======================> 歌曲 API
// 返回所有歌曲
export const getAllSong = () => get(`song`)
// 返回指定歌手ID的歌曲
export const getSongOfSingerId = (id) => get(`song/singer/detail?singerId=${id}`)
// 返回的指定用户ID收藏列表
export const getSongOfId = (id) => get(`song/detail?id=${id}`)
// 返回指定歌手名的歌曲
export const getSongOfSingerName = (id) => get(`song/singerName/detail?name=${id}`)
// 更新歌曲信息
export const updateSongMsg = (params) => post(`song/update`, params)
// 删除歌曲
export const deleteSong = (id) => get(`song/delete?id=${id}`)




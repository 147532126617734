<template>
  <div>
    <div class="Echarts" ref="chart"></div>
  </div>
</template>
<script src="../../../assets/echarts-wordcloud.min.js"></script>
<script>
import {selectByNameOrLaber,getAllLables} from '../../../api/index'
let echarts = require("echarts/lib/echarts");
require("echarts/lib/chart/pie");
require("echarts/lib/component/tooltip");
export default {
  name: "Echarts",
  components: {},
  data: () => {
    return {
      chart: null,
      xData:[],
      List:[]
    };
  },
  beforeDestroy() {
    this.destoryChart();
  },
  props:{
    allLables: {
      type: Array,
      // default: function () { return [] }
      // default: () => []
    },
  },
 
  methods: {
    destoryChart() {
      if (this.chart) {
        this.chart.dispose();
        this.chart = null;
      }
    },
    myEcharts() {
      this.destoryChart();
      // 基于准备好的dom，初始化echarts实例
      this.chart = echarts.init(this.$refs.chart);
      var option = {
        // title: {
        //     text: '热点分析',
        //     link: 'https://www.baidu.com/s?wd=' + encodeURIComponent('ECharts'),
        //     x: 'center',
        //     textStyle: {
        //         fontSize: 23
        //     }

        // },
        // backgroundColor: '#F7F7F7',
      
        tooltip: {
            show: false
        },
        //右上角保存为图片按钮
        // toolbox: {
        //     feature: {
        //         saveAsImage: {
        //             iconStyle: {
        //                 normal: {
        //                     color: '#FFFFFF'
        //                 }
        //             }
        //         }
        //     }
        // },
        series: [{
            name: '热点分析',
            type: 'wordCloud',
            //size: ['9%', '99%'],
            sizeRange: [6, 66],
            //textRotation: [0, 45, 90, -45],
            rotationRange: [-45, 90],
            //shape: 'circle',
            width: '100%',
            textPadding: 0,
            autoSize: {
                enable: true,
                minSize: 6
            },
            textStyle: {
                normal: {
                    color: function() {
                        return 'rgb(' + [
                            Math.round(Math.random() * 160),
                            Math.round(Math.random() * 160),
                            Math.round(Math.random() * 160)
                        ].join(',') + ')';
                    }
                },
                emphasis: {
                    shadowBlur: 10,
                    shadowColor: '#333'
                }
            },
            data: [{
                name: "Jayfee",
                value: 666
            }, {
                name: "Nancy",
                value: 520
            }]
        }]
    };

    var JosnList = this.List;

    option.series[0].data = this.allLables;


    //  myChart.setOption(option);
    this.chart.on('click', function (params) {
                    //alert((params.name));
                    window.open('http://www.kjwc.xyz/ModsLabels?name=' + encodeURIComponent(params.name));
                    // this.$router.push({path: `/ModsLabels`, query: {id:encodeURIComponent(params.name)}})
                });

      // 使用刚指定的配置项和数据显示图表。
      this.chart.setOption(option);
    },
  },
   watch: {
    allLables(newValue) {
      console.log(newValue);
      this.myEcharts();
    },
  },
  mounted() {
    this.myEcharts();
  },
};
</script>
<style lang="less" scoped>
.Echarts {
  width: 100%;
  height: 305px;
  
}
</style>

<template>
  <div>
    <header>
      <p style="text-align: center; padding-top: 30px">
        <a href="/#">
          <img
            src="static/picture/logo_index.png"
            alt="Logo"
            style="height: 50px; max-width: 100%"
          />
        </a>
      </p>
    </header>

    <main class="main-content">
      <!--== Start Login Area Wrapper ==-->
      <section class="account-login-area">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-8 col-lg-7 col-xl-6">
              <div class="login-register-form-wrap">
                <div class="login-register-form">
                  <div class="form-title">
                    <h4 class="title">登-录</h4>
                  </div>
                  <form action="#">
                    <div class="row">
                      <div class="col-12">
                        <div class="form-group">
                          <input
                            class="form-control"
                            type="text"
                            placeholder="账号"
                            v-model="ruleForm.accnumber"
                          />
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-group">
                          <input
                            class="form-control"
                            type="password"
                            placeholder="密码"
                            v-model="ruleForm.userpass"
                          />
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-group">
                          <div ref="DingXiangYzm"></div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-group">
                          <div class="remember-forgot-info">
                            <div class="remember"></div>
                            <div class="forgot-password">
                              <a href="/forgetPass">忘记密码</a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-group">
                          <button
                            type="button"
                            class="btn-theme"
                            id="btn-popup"
                          >
                            点击登录
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                  <div class="login-register-form-info">
                    <p>还没有账号? <a href="/registration">点击注册</a></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!--== End Login Area Wrapper ==-->
    </main>
  </div>
</template>
<script
  src="https://cdn.dingxiang-inc.com/ctu-group/captcha-ui/index.js"
  crossorigin="anonymous"
></script>
<script>
import { getLoginStatus,getUserOfAccnumber } from "../../api/index";
export default {
  data() {
    return {
      yztoken: "",
      ruleForm: {
        accnumber: "", //账号
        userpass: "", //密码
      },
      yznum:0,
      tt: "",
    };
  },
  methods: {
    async selUsers() {
      let res = await getUserOfAccnumber(window.sessionStorage.getItem("name"));
       if(res.pic==null&&res.username==='未命名'){
          this.$notify.info({
                title: "系统通知",
                message: "您还未设置用户名和头像，请前往个人中心设置！",
              });
      }
    },
    tosubmit() {
      if (this.ruleForm.accnumber.length == 0) {
        this.$alert("请输入账号！", "错误", {
          cancelButtonText: "确定",
          type: "error",
        });
        return;
      }
      if (this.ruleForm.userpass.length == 0) {
        this.$alert("请输入密码！", "错误", {
          cancelButtonText: "确定",
          type: "error",
        });
        return;
      }
      return 0;
    },
    submitForm() {
      if (this.ruleForm.accnumber.length == 0) {
        this.$alert("请输入账号！", "错误", {
          cancelButtonText: "确定",
          type: "error",
        }).then(() => {
          location.reload(); //刷新页面
        });
        return;
      }
      if (this.ruleForm.userpass.length == 0) {
        this.$alert("请输入密码！", "错误", {
          cancelButtonText: "确定",
          type: "error",
        }).then(() => {
          location.reload(); //刷新页面
        });
        return;
      }
      if (this.yztoken) {
        let params = new URLSearchParams();
        params.append("accnumber", this.ruleForm.accnumber);
        params.append("userpass", this.ruleForm.userpass);
        getLoginStatus(params)
          .then((res) => {
            console.log(res);
            if (res.code === 1) {
              window.sessionStorage.setItem("token", res.token);
              window.sessionStorage.setItem("name", res.name);
              this.$router.push("/#");
              this.$message.success("欢迎回来");
              this.selUsers()
              
            } else if (res.code === 0) {
              this.$message.error("账号或密码错误");
            } else if (res.code === 2) {
              this.$message.error("账号已被冻结！禁止登录");
            }else if (res.code === 3) {
              this.$message.error("账号异常，禁止登录！");
            }else if (res.code === 4) {
              this.$message.error("账号已被废弃，禁止登录！");
            }else {
              this.notify("登录失败", "error");
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.$message.error("请验证后进行登录");
      }
    },
  },
  mounted() {
    var todx = _dx.Captcha(this.$refs.DingXiangYzm, {
      // appId, 在控制台应用管理或应用配置模块获取
      appId: "dc16bbc6f6580d55c3389c7667706c1e", //appId，在控制台应用管理或应用配置模块获取
      apiServer: "https://api-pro.dingxiang-inc.com",
      style: "popup",
      success: (token) => {
        // 获取验证码token，用于后端校验，注意获取token若是sl开头的字符串，则是前端网络不通生成的降级token,请检查前端网络及apiServer地址。
        //console.log(token);
        this.yztoken = token;
        this.yznum++
        //隐藏验证
        todx.hide();
        this.submitForm();
        //console.log(this.yztoken);
      },
    });
    document.getElementById("btn-popup").onclick = function () {    
        //重置验证
        todx.reload();
        //展示验证
        todx.show();
    };
  },
};
</script>

<template>
    <div>
        <section class="job-category-area">
                <div class="container" vue-aos="fade-down">
                    <div class="row">
                    <div class="col-12">
                        <div class="section-title text-center">
                        <h3 class="title">标签检索</h3>
                        <div class="desc">
                            <p>点击标签或检索你想要的内容</p>
                        </div>
                        </div>
                    </div>
                    </div>

                    <div style="padding-bottom: 20px;"></div>
                        <div class="row row-gutter-20">
                            <div class="col-sm-6 col-lg-3" v-for="(item,index) in Lables" :key="index">
                                <!--== Start Job Category Item ==-->
                                <router-link :to="{path:'/ModsLabels',query: {name:item.labelsname}}">
                                <div class="job-category-item">
                                    <div class="content">
                                        <h3 class="title"><a href="#">{{item.labelsname}}</a></h3>
                                    </div>
                                    <a href="" v-if="item.assets==1">Word模板</a>
                                    
                                </div>
                                </router-link> 
                                <!--== End Job Category Item ==-->
                            </div>                                                 
                        </div>
                        <Echars :allLables="allLables"></Echars>
                        <div class="btns">
                            <!-- <button type="button" class="btn-theme" id="btn-popup">
                            --查看全部--
                            </button> -->
                        </div>
                </div>
            </section>
    </div>
</template>
<script>
import {getAllLables,selectByNameOrLaber} from '../api/index'
import Echars from '../views/home/echarts/first'
export default {
    data(){
        return{
            allLables:[],
            Lables:[],
        }
    },
    components:{
    Echars  
    },
   methods:{
        valueNum(val){
            if(val=="1"){
                return 100
            }else if(val=="2"){
                return 50
            }else if(val=="3"){
                return 30
            }else if(val=="4"){
                return 20
            }else if(val=="5"){
                return 10
            }else if(val=="999"){
                return 5
            }else{
                return 5
            }
        },
       async getLables(){
          let res=await getAllLables()
          //console.log('全部标签：',this.allLables);
          res.forEach(item=>{
            this.allLables.push({
                    name: item.labelsname,
                    value: this.valueNum(item.labelspriority)
                })
            // this.getOfLabels(item.labelsname)
          })
          this.Lables=res.slice(0,8)
    },
    async getOfLabels(val){
            //this.allLables = []
            await selectByNameOrLaber(val).then(res => {
                this.allLables.push({
                    name: val,
                    value: res.length
                })
            })                         
    },
   },
   created(){
      //1.获取标签
      this.getLables()
  },
}
</script>
<style>
.btns{
    text-align: center
}
</style>
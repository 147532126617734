<template>
  <div>
    <header class="header-area transparent">
      <div>
        <div class="container">
          <div class="row no-gutter align-items-center position-relative">
            <div class="col-12">
              <div class="header-align">
                <div class="header-align-start">
                  <div class="header-logo-area">
                    <a href="/#">
                      <img
                        class="logo-main"
                        src="static/picture/logo_index.png"
                        alt="Logo"
                      />
                      <img
                        class="logo-light"
                        src="static/picture/logo_index.png"
                        alt="Logo"
                      />
                    </a>
                  </div>
                </div>
                <div class="header-align-center">
                  <div class="header-navigation-area position-relative">
                    <ul class="main-menu nav">
                      <li>
                        <a href="/#"><span>首页</span></a>
                      </li>
                      <li class="has-submenu">
                        <router-link :to="{ path: '/BusinessView' }"
                          ><span>付费业务</span></router-link
                        >
                        <ul class="submenu-nav">
                          <li>
                            <a href="/#"><span>网站定制与开发</span></a>
                          </li>
                          <li>
                            <a href="/BusinessWordView"
                              ><span>Word制作与美化</span></a
                            >
                          </li>
                          <li>
                            <a href="/BusinessPptView"
                              ><span>PPT制作与美化</span></a
                            >
                          </li>
                          <li>
                            <a href="/BusinessExcelView"
                              ><span>Excel表格制作与美化 </span></a
                            >
                          </li>
                          <li>
                            <a href="/BusinessPsView"
                              ><span>平面广告设计与电商美工</span></a
                            >
                          </li>
                        </ul>
                      </li>
                      <li class="has-submenu">
                        <a href="/ModsView"><span>模板分享</span></a>
                        <ul class="submenu-nav">
                          <li>
                            <a href="/ModsOf?key=word"><span>Word模板</span></a>
                          </li>
                          <li>
                            <a href="/ModsOf?key=ppt"><span>PPT模板</span></a>
                          </li>
                          <li>
                            <a href="/ModsOf?key=excel"
                              ><span>Excel模板</span></a
                            >
                          </li>
                          <li>
                            <a href="/ModsOf?key=pic"><span>广告设计</span></a>
                          </li>
                        </ul>
                      </li>
                      <li class="has-submenu">
                        <a href="#/"><span>资源共享</span></a>
                      </li>
                      <li class="has-submenu">
                        <a href="/newsof"><span>新闻中心</span></a>
                      </li>
                      <li>
                        <a href="/aboutus"><span>关于我们</span></a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="header-align-end">
                  <div class="header-action-area">
                    <div v-if="this.token && this.name">
                      <!-- 用户名下拉菜单 -->
                      <el-dropdown
                        class="user-name"
                        trigger="click"
                        @command="handleCommand"
                      >
                        <div class="users-pics">
                          <img
                            class="user-pic"
                            src="../assets/img/picren.png"
                          /> 
                        </div>
                        <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item command="usercenter" >个人中心</el-dropdown-item>
                          <el-dropdown-item command="loginout"  divided>退出登录</el-dropdown-item>
                        </el-dropdown-menu>
                      </el-dropdown>
                    </div>
                    <a v-else class="btn-registration" href="/login">登录</a>
                    <button
                      class="btn-menu"
                      type="button"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#AsideOffcanvasMenu"
                      aria-controls="AsideOffcanvasMenu"
                    >
                      <i class="icofont-navigation-menu"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>

    <!--== Scroll Top Button ==-->
    <div id="scroll-to-top" class="scroll-to-top">
      <span class="icofont-rounded-up"></span>
    </div>

    <!--== Start Aside Menu ==-->
    <aside
      class="off-canvas-wrapper offcanvas offcanvas-start"
      tabindex="-1"
      id="AsideOffcanvasMenu"
      aria-labelledby="offcanvasExampleLabel"
    >
      <div class="offcanvas-header">
        <h1 class="d-none" id="offcanvasExampleLabel">Aside Menu</h1>
        <button
          class="btn-menu-close"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        >
          科技微创 <i class="icofont-simple-left"></i>
        </button>
      </div>
      <div class="offcanvas-body">
        <!-- Mobile Menu Start -->
        <div class="mobile-menu-items">
          <ul class="nav-menu">
            <li><a href="/#">首页</a></li>
            <li>
              <a href="/BusinessView">付费业务</a>
              <ul class="sub-menu">
                <li><a href="/#">网站定制与开发</a></li>
                <li><a href="/BusinessWordView">Word制作与美化</a></li>
                <li><a href="/BusinessPptView">PPT制作与美化</a></li>
                <li><a href="/BusinessExcelView">Excel制作与美化</a></li>
                <li><a href="/BusinessPsView">广告设计与美工</a></li>
              </ul>
            </li>
            <li>
              <a href="/ModsView">模板分享</a>
              <ul class="sub-menu">
                <li><a href="/ModsOf?key=word">Word模板</a></li>
                <li><a href="/ModsOf?key=ppt">PPT模板</a></li>
                <li><a href="/ModsOf?key=excel">Excel模板</a></li>
                <li><a href="/ModsOf?key=pic">广告设计</a></li>
              </ul>
            </li>
            <li><a href="#">资源共享</a></li>
            <li><a href="/newsof">新闻中心</a></li>
            <li><a href="/aboutus">关于我们</a></li>
          </ul>
        </div>
        <!-- Mobile Menu End -->
      </div>
    </aside>
    <!--== End Aside Menu ==-->
  </div>
</template>
<script>
import { mixin } from "../mixins";
import { getUserOfAccnumber } from "../api/index";
export default {
  mixins: [mixin],
  data() {
    return {
      name: window.sessionStorage.getItem("name"),
      token: window.sessionStorage.getItem("token"),
      users: [],
    };
  },
  methods: {
    async selUsers() {
      let res = await getUserOfAccnumber(this.name);
      this.users = res;
    },
    handleCommand(command) {
        if(command==='usercenter'){
          console.log("个人中心");          
          this.$router.push('/usercenter') 
        }
        if(command==='loginout'){
          console.log("退出");
          // 清空token
          window.sessionStorage.clear()
           this.$message({
          showClose: true,
          type: 'success',
          message: '退出登录成功，为您跳转至登录页面!'
        });
          // 跳转到登录页
          this.$router.push('/login')  
        }
      }
    
  },
  created() {
    if (this.name && this.token) {
      this.selUsers();
    }
  },
};
</script>
<style>
.user-pic {
  width: 50px;
  height: 50px;
  border-radius: 25px;
}
.backgr {
  background: no-repeat;
  width: 100%;
  height: 100%;
  background-image: url("@/../../../public/assets/img/slider/banner1031.jpg");
}
</style>

<template>
    <div>

        <!--== Start Footer Area Wrapper ==-->
        <footer class="footer-area">
            <!--== Start Footer Top ==-->
            <div class="footer-top">
            <div class="container pt--0 pb--0">
                <div class="row">
                <div class="col-lg-5">
                    <div class="footer-newsletter-content">
                    <h4 class="title">输入内容搜索关于本网站您想要了解的一切</h4>
                    </div>
                </div>
                <div class="col-lg-7">
                    <div class="footer-newsletter-form">
                    <form action="#">
                        <input type="email" placeholder="请输入内容" v-model="name">
                        <button type="submit" class="btn-newsletter" @click="toByNameOrLabers()">搜索</button>
                    </form>
                    </div>
                </div>
                </div>
            </div>
            </div>
            <!--== End Footer Top ==-->

            <!--== Start Footer Main ==-->
            <div class="footer-main">
            <div class="container pt--0 pb--0">
                <div class="row">
                <div class="col-md-6 col-lg-3">
                    <div class="widget-item widget-about">
                    <div class="widget-logo-area">
                        <a href="">
                        <img class="logo-main" src="static/picture/logo_index_tm.png" alt="Logo">
                        </a>
                    </div>
                    <p class="desc">科技微型创业，传递你我梦想<br>Technology start a business , delivering your dreams</p>
                    <div class="social-icons">
                        <!--
                        <a href="javascript:;" target="_blank" rel="noopener"><i class="icofont-facebook"></i></a>
                        <a href="javascript:;" target="_blank" rel="noopener"><i class="icofont-skype"></i></a>
                        <a href="javascript:;" target="_blank" rel="noopener"><i class="icofont-twitter"></i></a>
                        -->
                    </div>
                    </div>
                </div>




                <div class="col-lg-9">
                    <div class="row">
                    <div class="col-md-3 col-lg-3">
                        <div class="widget-item nav-menu-item1">
                        <h4 class="widget-title"><a href="/BusinessView">付费业务</a></h4>
                        <h4 class="widget-collapsed-title collapsed" data-bs-toggle="collapse" data-bs-target="#widgetId-1">付费业务</h4>
                        <div id="widgetId-1" class="collapse widget-collapse-body">
                            <div class="collapse-body">
                            <div class="widget-menu-wrap">
                                <ul class="nav-menu">
                                <li><a href="/#">网站定制与开发</a></li>
                                <li><a href="/BusinessWordView">Word制作与美化</a></li>
                                <li><a href="/BusinessPptView">PPT制作与美化</a></li>
                                <li><a href="/BusinessExcelView">Excel制作与美化 </a></li>
                                <li><a href="/BusinessPsView">广告设计与美工 </a></li>
                                </ul>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div class="col-md-3 col-lg-3">
                        <div class="widget-item nav-menu-item2">
                        <h4 class="widget-title"><a href="/ModsView">模板分享</a></h4>
                        <h4 class="widget-collapsed-title collapsed" data-bs-toggle="collapse" data-bs-target="#widgetId-2">模板分享</h4>
                        <div id="widgetId-2" class="collapse widget-collapse-body">
                            <div class="collapse-body">
                            <div class="widget-menu-wrap">
                                <ul class="nav-menu">
                                <li><a href="/ModsOf?key=word">Word模板</a></li>
                                <li><a href="/ModsOf?key=ppt">PPT模板</a></li>
                                <li><a href="/ModsOf?key=excel">Excel模板</a></li>
                                <li><a href="/ModsOf?key=pic">广告设计</a></li>
                                </ul>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div class="col-md-3 col-lg-3">
                        <div class="widget-item nav-menu-item3">
                        <h4 class="widget-title">资源分享</h4>
                        <h4 class="widget-collapsed-title collapsed" data-bs-toggle="collapse" data-bs-target="#widgetId-3">资源分享</h4>
                        <div id="widgetId-3" class="collapse widget-collapse-body">
                            <div class="collapse-body">
                            <div class="widget-menu-wrap">
                                <ul class="nav-menu">
                                <li><a href="javascript:;">资源共享区</a></li>

                                </ul>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div class="col-md-3 col-lg-3">
                        <div class="widget-item nav-menu-item4">
                        <h4 class="widget-title">关于我们</h4>
                        <h4 class="widget-collapsed-title collapsed" data-bs-toggle="collapse" data-bs-target="#widgetId-4">关于我们</h4>
                        <div id="widgetId-4" class="collapse widget-collapse-body">
                            <div class="collapse-body">
                            <div class="widget-menu-wrap">
                                <ul class="nav-menu">
                                <li><a href="/aboutus">了解我们</a></li>
                                <li><a href="#">给我们留言</a></li>

                                </ul>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
            <!--== End Footer Main ==-->

            <!--== Start Footer Bottom ==-->
            <div class="footer-bottom">
            <div class="container pt--0 pb--0">
                <div class="row">
                <div class="col-12">
                    <div class="footer-bottom-content">
                    <div style="text-align: center;">
                        科技微创版权所有©2020-2023.
                        <a target="_blank" href="http://www.kjwc.xyz/">科技微创</a>
                        <br>
                        Copyright&copy;kjwc.xyz, All Rights Reserved.
                        <br>
                        <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=22062302660156"
                        style="display:inline-block;text-decoration:none;height:20px;line-height:20px;">
                        <img src="assets/img/batb1.png" style="float:left;"/>
                        <p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">
                            吉公网安备 22062302660156号</p></a>
                        <a href="https://beian.miit.gov.cn/">
                        <p>吉ICP备2020006410号-1
                        </p>
                        </a>



                    </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
            <!--== End Footer Bottom ==-->
        </footer>
        <!--== End Footer Area Wrapper ==-->

    </div>
</template>
<script>
export default {
    data(){
        return{
            name:'',
            newName:''
        }
    },
    methods:{
        toByNameOrLabers(){
            this.$router.push({path: `../ModsBy`, query: {name:this.name.trim()}})
        }
    }
}
</script>


<template>
    <div>
        <!--== Start Header Wrapper ==-->
        <header >
        <p style="text-align: center;padding-top: 30px;">
            <a href="/#">
            <img src="static/picture/logo_index.png" alt="Logo" style="height: 50px;max-width: 100%;">
            </a>
        </p>
        </header>
        <!--== End Header Wrapper ==-->  
        <main class="main-content">
            <!--== Start Login Area Wrapper ==-->
            <section class="account-login-area">
                <div class="container">
                <div class="row justify-content-center">
                <div class="col-md-8 col-lg-7 col-xl-6">
                    <div class="login-register-form-wrap">
                    <div class="login-register-form">
                        <div class="form-title">
                        <h4 class="title">注-册</h4>
                        </div>
                        <form :model="registerForm">
                        <div class="row">
                            <div class="col-12">
                            <div class="form-group">
                                <input class="form-control" type="text" placeholder="账号" v-model="registerForm.accnumber">
                            </div>
                            </div>
                            <div class="col-12">
                            <div class="form-group">
                                <input class="form-control" type="password" placeholder="密码" v-model="registerForm.userpass">
                            </div>
                            </div>
                            <div class="col-12">
                            <div class="form-group">
                                <input class="form-control" type="password" placeholder="确认密码" v-model="registerForm.conpass">
                            </div>
                            </div>
                            <div class="col-12">
                            <div class="form-group">
                                <input class="form-control" type="text" placeholder="请输入手机号(仅用于忘记密码时找回密码)" v-model="registerForm.phone">
                            </div>
                            </div>
                            <div class="col-12">
                            <div class="form-group">
                                <div class="remember-forgot-info">
                                <div class="remember">
                                    <input class="form-check-input" type="checkbox" value="" id="defaultCheck1">
                                    <label class="form-check-label" for="defaultCheck1">我已阅读并同意本网站隐私协议和免责声明</label>
                                </div>                               
                                </div>
                            </div>
                            </div>                        
                            <div class="col-12">
                            <div class="form-group">
                                <button type="button" class="btn-theme" @click="ToRegis">点击注册</button>
                            </div>
                            </div>
                        </div>
                        </form>
                        <div class="login-register-form-info">
                        <p>已有账号? <a href="/login">点击登录</a></p>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </section>
            <!--== End Login Area Wrapper ==-->
        </main>
    </div>
</template>

<script>
import {getRegisUser} from '../../api/index'
export default {
    data(){
        return{
            registerForm:{
                accnumber:'',//账号
                userpass:'',//密码
                conpass:'',//确认密码
                phone:'',//手机号

            }
        }
    },
    methods:{
        ToRegis(){
            let userpass=this.registerForm.userpass;
            let conpass=this.registerForm.conpass;
            if(userpass===conpass){
                if(this.registerForm.accnumber.length != 0 && this.registerForm.phone.length != 0){
                    this.Regis()
                }
                if(this.registerForm.accnumber.length == 0){
                    this.$alert('请输入账号！', '错误', {
                        cancelButtonText: '确定',
                        type: 'error',                       
                    });
                }
                if(this.registerForm.phone.length == 0){
                    this.$alert('请输入手机号！', '错误', {
                        cancelButtonText: '确定',
                        type: 'error',                       
                    });
                }
            }else{
                this.$alert('请确认您输入的两次密码一致！', '错误', {
                        cancelButtonText: '确定',
                        type: 'error',                       
                    });
            }
        },
        Regis(){
            console.log('获取账号：',this.registerForm.accnumber);
            let params = new URLSearchParams()
            params.append('accnumber', this.registerForm.accnumber)   
            params.append('userpass', this.registerForm.userpass)
            params.append('phone', this.registerForm.phone)

            getRegisUser(params).then(res => {
                if (res.code === 1) {
                    //this.registerForm = {}
                    this.$alert('注册成功！', '恭喜你', {
                        confirmButtonText: '确定',
                        type: 'success '
                    });  
                }else {
                    switch(res.code){
                        case 0:
                            this.$alert('注册失败！', '错误', {
                                cancelButtonText: '确定',
                                type: 'error',                            
                            });
                            break;
                        case 2:
                            this.$alert('手机号存在，请更换手机号注册或切换至登录！', '错误', {
                                cancelButtonText: '确定',
                                type: 'error',                          
                            });
                            break;
                        case 3:
                            this.$alert('账号已存在，请更换您要注册的账号', '错误', {
                                cancelButtonText: '确定',
                                type: 'error',                          
                            });
                            break;
                    }
                }
            })
                .catch(err => {
                console.log(err)
                })
        }
    }
}
</script>
import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '../views/layout/Layout.vue'
import Home from '../views/home/HomeView.vue'
import Login from '../views/login/Login.vue'
import Registration from '../views/login/Registration.vue'
import forgetPass from '../views/login/forgetPass.vue'

Vue.use(VueRouter)

const routes = [
  {
    path:'/',
    name:'Layout',
    component:Layout,
    children:[
        {
            path:'/',
            name:'home',
            component:Home,
        },
        {
          path:'/ModsView',
          component: resolve => require(['../views/mods/modsHome.vue'], resolve),
          meta: { title: 'Mods' }
        },
        {
          path:'/ModsOf',
          component: resolve => require(['../views/mods/modsof.vue'], resolve),
          meta: { title: 'ModsOf' }
        },
        {
          path:'/ModsBy',
          component: resolve => require(['../views/mods/modby.vue'], resolve),
          meta: { title: 'ModsBy' }
        },
        {
          path:'/ModsLabels',
          component: resolve => require(['../views/mods/modsLabels.vue'], resolve),
          meta: { title: 'ModsLabels' }
        },
        {
          path:'/ModList',
          component: resolve => require(['../views/mods/modList.vue'], resolve),
          meta: { title: 'ModList' }
        },
        {
          path:'/BusinessView',
          component: resolve => require(['../views/business/BusinessHome.vue'], resolve),
          meta: { title: 'Business' }
        },
        {
          path:'/BusinessWordView',
          component: resolve => require(['../views/business/BusinessWord.vue'], resolve),
          meta: { title: 'BusinessWord' }
        },
        {
          path:'/BusinessExcelView',
          component: resolve => require(['../views/business/BusinessExcel.vue'], resolve),
          meta: { title: 'BusinessExcel' }
        },
        {
          path:'/BusinessPptView',
          component: resolve => require(['../views/business/BusinessPpt.vue'], resolve),
          meta: { title: 'BusinessPpt' }
        },
        {
          path:'/BusinessPsView',
          component: resolve => require(['../views/business/BusinessPs.vue'], resolve),
          meta: { title: 'BusinessPs' }
        },
        {
          path:'/aboutus',
          component: resolve => require(['../views/aboutus/aboutUs.vue'], resolve),
          meta: { title: 'aboutus' }
        },
        {
          path:'/usercenter',
          component: resolve => require(['../views/users/userCenter.vue'], resolve),
          meta: { title: 'usercenter' }
        },
        {
          path:'/newList',
          component: resolve => require(['../views/news/newsList.vue'], resolve),
          meta: { title: 'newList' }
        },
        {
          path:'/newsof',
          component: resolve => require(['../views/news/newsof.vue'], resolve),
          meta: { title: 'newsof' }
        },
        {
          path:'/404',
          component: resolve => require(['../views/err/404.vue'], resolve),
          meta: { title: '404' }
        },

        
        
    ]
  },
  {
      path:'/login',
      nameL:'login',
      component:Login
  },
  {
    path:'/forgetPass',
    nameL:'forgetPass',
    component:forgetPass
},
  {
    path:'/registration',
    nameL:'registration',
    component:Registration
  }
 
]

const router = new VueRouter({
    mode: 'history',
    //base: process.env.BASE_URL,
    routes,
    //设置路由跳转后滚动到页首
    scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition
      } else {
        return { x: 0, y: 0 }
      }
    }
  
  })

  export default router
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('header',{staticClass:"header-area transparent"},[_c('div',[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row no-gutter align-items-center position-relative"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"header-align"},[_vm._m(0),_c('div',{staticClass:"header-align-center"},[_c('div',{staticClass:"header-navigation-area position-relative"},[_c('ul',{staticClass:"main-menu nav"},[_vm._m(1),_c('li',{staticClass:"has-submenu"},[_c('router-link',{attrs:{"to":{ path: '/BusinessView' }}},[_c('span',[_vm._v("付费业务")])]),_vm._m(2)],1),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6)])])]),_c('div',{staticClass:"header-align-end"},[_c('div',{staticClass:"header-action-area"},[(this.token && this.name)?_c('div',[_c('el-dropdown',{staticClass:"user-name",attrs:{"trigger":"click"},on:{"command":_vm.handleCommand}},[_c('div',{staticClass:"users-pics"},[_c('img',{staticClass:"user-pic",attrs:{"src":require("../assets/img/picren.png")}})]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',{attrs:{"command":"usercenter"}},[_vm._v("个人中心")]),_c('el-dropdown-item',{attrs:{"command":"loginout","divided":""}},[_vm._v("退出登录")])],1)],1)],1):_c('a',{staticClass:"btn-registration",attrs:{"href":"/login"}},[_vm._v("登录")]),_vm._m(7)])])])])])])])]),_vm._m(8),_vm._m(9)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header-align-start"},[_c('div',{staticClass:"header-logo-area"},[_c('a',{attrs:{"href":"/#"}},[_c('img',{staticClass:"logo-main",attrs:{"src":"static/picture/logo_index.png","alt":"Logo"}}),_c('img',{staticClass:"logo-light",attrs:{"src":"static/picture/logo_index.png","alt":"Logo"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"/#"}},[_c('span',[_vm._v("首页")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"submenu-nav"},[_c('li',[_c('a',{attrs:{"href":"/#"}},[_c('span',[_vm._v("网站定制与开发")])])]),_c('li',[_c('a',{attrs:{"href":"/BusinessWordView"}},[_c('span',[_vm._v("Word制作与美化")])])]),_c('li',[_c('a',{attrs:{"href":"/BusinessPptView"}},[_c('span',[_vm._v("PPT制作与美化")])])]),_c('li',[_c('a',{attrs:{"href":"/BusinessExcelView"}},[_c('span',[_vm._v("Excel表格制作与美化 ")])])]),_c('li',[_c('a',{attrs:{"href":"/BusinessPsView"}},[_c('span',[_vm._v("平面广告设计与电商美工")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"has-submenu"},[_c('a',{attrs:{"href":"/ModsView"}},[_c('span',[_vm._v("模板分享")])]),_c('ul',{staticClass:"submenu-nav"},[_c('li',[_c('a',{attrs:{"href":"/ModsOf?key=word"}},[_c('span',[_vm._v("Word模板")])])]),_c('li',[_c('a',{attrs:{"href":"/ModsOf?key=ppt"}},[_c('span',[_vm._v("PPT模板")])])]),_c('li',[_c('a',{attrs:{"href":"/ModsOf?key=excel"}},[_c('span',[_vm._v("Excel模板")])])]),_c('li',[_c('a',{attrs:{"href":"/ModsOf?key=pic"}},[_c('span',[_vm._v("广告设计")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"has-submenu"},[_c('a',{attrs:{"href":"#/"}},[_c('span',[_vm._v("资源共享")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"has-submenu"},[_c('a',{attrs:{"href":"/newsof"}},[_c('span',[_vm._v("新闻中心")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"/aboutus"}},[_c('span',[_vm._v("关于我们")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"btn-menu",attrs:{"type":"button","data-bs-toggle":"offcanvas","data-bs-target":"#AsideOffcanvasMenu","aria-controls":"AsideOffcanvasMenu"}},[_c('i',{staticClass:"icofont-navigation-menu"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"scroll-to-top",attrs:{"id":"scroll-to-top"}},[_c('span',{staticClass:"icofont-rounded-up"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('aside',{staticClass:"off-canvas-wrapper offcanvas offcanvas-start",attrs:{"tabindex":"-1","id":"AsideOffcanvasMenu","aria-labelledby":"offcanvasExampleLabel"}},[_c('div',{staticClass:"offcanvas-header"},[_c('h1',{staticClass:"d-none",attrs:{"id":"offcanvasExampleLabel"}},[_vm._v("Aside Menu")]),_c('button',{staticClass:"btn-menu-close",attrs:{"data-bs-dismiss":"offcanvas","aria-label":"Close"}},[_vm._v(" 科技微创 "),_c('i',{staticClass:"icofont-simple-left"})])]),_c('div',{staticClass:"offcanvas-body"},[_c('div',{staticClass:"mobile-menu-items"},[_c('ul',{staticClass:"nav-menu"},[_c('li',[_c('a',{attrs:{"href":"/#"}},[_vm._v("首页")])]),_c('li',[_c('a',{attrs:{"href":"/BusinessView"}},[_vm._v("付费业务")]),_c('ul',{staticClass:"sub-menu"},[_c('li',[_c('a',{attrs:{"href":"/#"}},[_vm._v("网站定制与开发")])]),_c('li',[_c('a',{attrs:{"href":"/BusinessWordView"}},[_vm._v("Word制作与美化")])]),_c('li',[_c('a',{attrs:{"href":"/BusinessPptView"}},[_vm._v("PPT制作与美化")])]),_c('li',[_c('a',{attrs:{"href":"/BusinessExcelView"}},[_vm._v("Excel制作与美化")])]),_c('li',[_c('a',{attrs:{"href":"/BusinessPsView"}},[_vm._v("广告设计与美工")])])])]),_c('li',[_c('a',{attrs:{"href":"/ModsView"}},[_vm._v("模板分享")]),_c('ul',{staticClass:"sub-menu"},[_c('li',[_c('a',{attrs:{"href":"/ModsOf?key=word"}},[_vm._v("Word模板")])]),_c('li',[_c('a',{attrs:{"href":"/ModsOf?key=ppt"}},[_vm._v("PPT模板")])]),_c('li',[_c('a',{attrs:{"href":"/ModsOf?key=excel"}},[_vm._v("Excel模板")])]),_c('li',[_c('a',{attrs:{"href":"/ModsOf?key=pic"}},[_vm._v("广告设计")])])])]),_c('li',[_c('a',{attrs:{"href":"#"}},[_vm._v("资源共享")])]),_c('li',[_c('a',{attrs:{"href":"/newsof"}},[_vm._v("新闻中心")])]),_c('li',[_c('a',{attrs:{"href":"/aboutus"}},[_vm._v("关于我们")])])])])])])
}]

export { render, staticRenderFns }
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import AOS from "aos";
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import "../node_modules/aos/dist/aos.css";
import * as echarts from 'echarts';
import 'echarts-wordcloud'
Vue.prototype.$echarts = echarts;
Vue.use(echarts)
Vue.use(ElementUI)
Vue.use(AOS)
Vue.config.productionTip = false
//导入初始化样式
import './assets/css/base.css'
new Vue({
  router,

  render: h => h(App),
}).$mount('#app')
